import ArticleCard, { ArticleCardVariant } from "components/ArticleCard";
import Button from "components/Button";
import Carousel from "components/Carousel";
import ContainerSection from "components/ContainerSection";
import Link from "next/link";
import { ReactElement, useState } from "react";
import { useArticlesQuery } from "utils/api/hooks";

const SUBCATEGORIES = [
  { label: "Konkursy", categoryId: 15 },
  { label: "Nadgodziny", categoryId: 16 },
  //{ label: "Quizy", categoryId: 17 },
];

export default function NaLuzie(): ReactElement {
  const [activeSubcategory, setActiveSubcategory] = useState(0);

  const actionMagQuery = useArticlesQuery({
    categoryId: activeSubcategory > 0 ? activeSubcategory : "in:14,15,16,17",
    sort: "-isFeatured,-isPinned,-publishedAt",
    isRecommended: false,
  });

  return (
    <ContainerSection className="grid grid-cols-12 my-8 space-y-5 lg:space-x-5">
      <div className="flex justify-between col-span-12 lg:col-span-3 lg:flex-col lg:justify-start lg:items-start">
        <Link href={`/na-luzie`} passHref>
          <a className="text-2xl font-bold text-transparent uppercase lg:text-3xl bg-clip-text bg-gradient-to-br from-red to-red-200 dark:from-orange-600 dark:via-orange dark:to-orange-200 font-display">
            Na luzie
          </a>
        </Link>

        <div className="flex-col hidden w-3/4 mt-8 lg:flex">
          {SUBCATEGORIES.map((subcategory, index) => (
            <button
              key={`magazine-subcategory-${index}`}
              className={`py-2 text-2xl font-medium relative text-left ${
                subcategory.categoryId === activeSubcategory
                  ? `text-red dark:text-orange`
                  : `text-gray-500`
              } uppercase border-b font-display border-gray-100 dark:border-black-100 ${
                subcategory.categoryId === activeSubcategory &&
                `after:w-14 after:h-1 after:rounded after:absolute after:right-0 after:bottom-0 after:bg-red dark:after:bg-orange`
              }`}
              onClick={() => setActiveSubcategory(subcategory.categoryId)}
            >
              {subcategory.label}
            </button>
          ))}
        </div>

        <div className="flex items-start justify-end space-x-2 lg:hidden">
          <Link href={`/konkursy`} passHref>
            <Button $as="a">Wszystkie</Button>
          </Link>
        </div>
      </div>

      <div className="w-screen h-5 col-span-12 mx-auto overflow-hidden max-w-screen-off-left lg:hidden">
        <ul className="box-content flex flex-row h-full max-w-full pb-2 space-x-4 overflow-x-auto overflow-y-hidden">
          {SUBCATEGORIES.map((subcategory, index) => (
            <li
              key={`magazine-mobile-subcategory-${index}`}
              className={`whitespace-nowrap font-display text-lg font-medium leading-6 uppercase cursor-pointer ${
                subcategory.categoryId === activeSubcategory
                  ? "text-red dark:text-orange"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveSubcategory(subcategory.categoryId)}
            >
              {subcategory.label}
            </li>
          ))}
        </ul>
      </div>

      <div className="col-span-12 h-80 lg:hidden">
        <Carousel offset={0} placeholder={ArticleCard}>
          {actionMagQuery.data &&
            actionMagQuery.data.results.map((article, index) => (
              <ArticleCard
                key={`carousel-article-${index}`}
                article={article}
              />
            ))}
        </Carousel>
      </div>

      <div className="hidden grid-cols-12 lg:grid col-span-9 -m-2.5 children:m-2.5">
        {actionMagQuery.data?.results.slice(0, 3).map((article, index) => (
          <div
            key={`na-luzie-article-${index}`}
            className="flex col-span-12 sm:col-span-6 lg:col-span-4"
          >
            <ArticleCard
              variant={ArticleCardVariant.SeparatedCover}
              article={article}
            />
          </div>
        ))}
      </div>
    </ContainerSection>
  );
}
