import Button from "components/Button";
import Carousel from "components/Carousel";
import { ChevronLeft, ChevronRight, Comment } from "components/Icons";
import Tag from "components/Tag";
import VideoCard, { VideoCardVariant } from "components/VideoCard";
import { format, formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import Link from "next/link";
import { Fragment, ReactElement, useEffect, useRef, useState } from "react";
import { useVideosQuery } from "utils/api/hooks";
import { composeClassName } from "utils/misc";

export default function Videos(): ReactElement {
  const [carouselOffset, setCarouselOffset] = useState(0);
  const [isScreening, setIsScreening] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const sectionRef = useRef<HTMLDivElement>(null);

  const featuredVideosQuery = useVideosQuery({
    isFeaturedOnHomePage: true,
    isBlockedFromHomePage: false,
    sort: "-publishedAt",
  });

  useEffect(() => {
    function lazyLoadPlayer() {
      if (sectionRef.current) {
        const offset =
          sectionRef.current.offsetTop - window.scrollY - window.innerHeight;

        if (offset < 0) setIsLoaded(true);
      }
    }

    if (typeof window !== "undefined") {
      const observer = new MutationObserver(function () {
        setIsScreening(!!window.document.body.style.backgroundImage);
      });

      observer.observe(window.document.body, {
        attributes: true,
        attributeFilter: ["style"],
      });

      window.addEventListener("scroll", lazyLoadPlayer);

      return () => window.removeEventListener("scroll", lazyLoadPlayer);
    }
  }, []);

  return (
    <section
      ref={sectionRef}
      className={composeClassName("my-8 dark", !isScreening && "bg-black")}
    >
      <div
        className={composeClassName(
          "px-3 mx-auto max-w-screen-2xl 2xl:px-0 py-10",
          isScreening &&
            "bg-black before:left-0 before:right-0 2xl:before:-left-8 2xl:before:-right-8 before:top-0 before:-bottom-8 relative before:absolute before:bg-black children:z-10 children:relative"
        )}
      >
        <div className="grid grid-cols-12 lg:space-x-5">
          <div className="flex justify-between col-span-12 lg:col-span-3 lg:flex-col lg:items-start">
            <Link href={`/wideo`} passHref>
              <a className="text-3xl font-bold text-transparent uppercase bg-clip-text bg-gradient-to-br from-red to-red-200 font-display">
                Wideo
              </a>
            </Link>

            {featuredVideosQuery.data &&
              featuredVideosQuery.data.results.length > 0 && (
                <Link
                  href={`/wideo/${featuredVideosQuery.data.results[0].slug}`}
                  passHref
                >
                  <a className="z-10 hidden mt-auto lg:block">
                    <h2 className="mb-4 text-lg font-medium text-black lg:text-3xl dark:text-white lg:uppercase font-display line-clamp-5">
                      {featuredVideosQuery.data.results[0].title}
                    </h2>

                    <p className={`mb-4 text-gray-500 line-clamp-3`}>
                      {featuredVideosQuery.data.results[0].lead}
                    </p>

                    <div className={composeClassName("flex space-x-7 mt-auto")}>
                      <Tag
                        color={"text-black dark:text-white"}
                        border={"border-black dark:border-white"}
                        outlined
                      >
                        Wideo
                      </Tag>

                      <Tag
                        color={"text-black dark:text-white"}
                        border="border-transparent"
                        outlined
                      >
                        <Fragment>
                          {+new Date(
                            featuredVideosQuery.data.results[0].publishedAt
                          ) >=
                            +new Date() - 86400000 &&
                            formatDistanceToNow(
                              new Date(
                                featuredVideosQuery.data.results[0].publishedAt
                              ),
                              {
                                locale: pl,
                                addSuffix: true,
                              }
                            )}
                          {+new Date(
                            featuredVideosQuery.data.results[0].publishedAt
                          ) <
                            +new Date() - 86400000 &&
                            format(
                              new Date(
                                featuredVideosQuery.data.results[0].publishedAt
                              ),
                              "d.MM.yyyy",
                              {
                                locale: pl,
                              }
                            )}
                        </Fragment>
                      </Tag>

                      {!!featuredVideosQuery.data.results[0].commentCount && (
                        <div
                          className={composeClassName(
                            "flex items-center text-white"
                          )}
                        >
                          <Comment />

                          <span
                            className={`ml-3 font-bold font-mono text-xs uppercase`}
                          >
                            {featuredVideosQuery.data.results[0].commentCount}
                          </span>
                        </div>
                      )}
                    </div>
                  </a>
                </Link>
              )}
          </div>

          <div className="hidden lg:col-span-9 lg:block">
            {featuredVideosQuery.data &&
            featuredVideosQuery.data.results.length > 0 &&
            isLoaded ? (
              <VideoCard
                variant={VideoCardVariant.PrimaryWithoutData}
                video={featuredVideosQuery.data.results[0]}
              />
            ) : (
              <div className="w-full bg-gray-200 h-150 rounded-xl animate-pulse dark:bg-black-300" />
            )}
          </div>

          <div className="col-span-12 mt-5 lg:hidden">
            {featuredVideosQuery.data &&
              featuredVideosQuery.data.results.length > 0 && (
                <VideoCard video={featuredVideosQuery.data.results[0]} />
              )}
          </div>
        </div>

        <div className="mt-8">
          <div className="flex justify-between">
            <Link href={`/wideo`} passHref>
              <a className="text-2xl font-bold text-gray-500 uppercase font-display hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-br hover:from-red hover:to-red-200 dark:hover:from-orange-600 dark:hover:via-orange dark:hover:to-orange-200">
                Zobacz również
              </a>
            </Link>

            <div className="flex items-start justify-end space-x-2">
              <Link href={`/wideo`} passHref>
                <Button $as="a">Wszystkie</Button>
              </Link>

              <Button
                disabled={carouselOffset <= 0}
                onClick={() =>
                  setCarouselOffset(
                    (carouselOffset) =>
                      carouselOffset - (window?.innerWidth < 940 ? 1 : 4)
                  )
                }
                icon={<ChevronLeft />}
                className="hidden lg:flex"
              />

              <Button
                disabled={
                  process.browser && window.innerWidth < 940
                    ? carouselOffset > 5
                    : carouselOffset > 3
                }
                onClick={() =>
                  setCarouselOffset(
                    (carouselOffset) =>
                      carouselOffset + (window?.innerWidth < 940 ? 1 : 4)
                  )
                }
                icon={<ChevronRight />}
                className="hidden lg:flex"
              />
            </div>
          </div>

          <div className="w-full mt-5">
            <Carousel offset={carouselOffset}>
              {featuredVideosQuery.data &&
                featuredVideosQuery.data.results
                  .slice(1)
                  .map((video, index) => (
                    <VideoCard key={`carousel-video-${index}`} video={video} />
                  ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}
