import { Ad } from "components/Ads";
import ArticleCard, { ArticleCardVariant } from "components/ArticleCard";
import Button from "components/Button";
import Carousel from "components/Carousel";
import ContainerSection from "components/ContainerSection";
import Link from "next/link";
import { Fragment, ReactElement, useState } from "react";
import { useArticlesQuery } from "utils/api/hooks";
import { useWindowSize } from "utils/hooks";

const SUBCATEGORIES = [
  { label: "Publicystyka", categoryId: 4 },
  { label: "Zapowiedzi", categoryId: 5 },
  { label: "Już graliśmy", categoryId: 6 },
  //{ label: "Poradniki", categoryId: 7 },
];

export default function Texts(): ReactElement {
  const [activeSubcategory, setActiveSubcategory] = useState(4);
  const { width } = useWindowSize();

  const articlesQuery = useArticlesQuery({
    categoryId: activeSubcategory,
    sort: "-isFeatured,-isPinned,-publishedAt",
    isRecommended: false,
  });

  return (
    <ContainerSection className="grid grid-cols-12 my-8 space-y-5 lg:space-x-5">
      <div className="flex flex-wrap justify-between col-span-12 row-span-2 lg:col-span-3 lg:flex-col lg:justify-start lg:items-start">
        <Link href={`/teksty`} passHref>
          <a className="text-2xl font-bold text-transparent uppercase lg:text-3xl bg-clip-text bg-gradient-to-br from-red to-red-200 dark:from-orange-600 dark:via-orange dark:to-orange-200 font-display">
            Teksty
          </a>
        </Link>

        <div className="flex-col hidden w-3/4 mt-8 lg:flex">
          {SUBCATEGORIES.map((subcategory, index) => (
            <button
              key={`texts-subcategory-${index}`}
              className={`py-2 text-2xl font-medium relative text-left ${
                subcategory.categoryId === activeSubcategory
                  ? `text-red dark:text-orange`
                  : `text-gray-500`
              } uppercase border-b font-display border-gray-100 dark:border-black-100 ${
                subcategory.categoryId === activeSubcategory &&
                `after:w-14 after:h-1 after:rounded after:absolute after:right-0 after:bottom-0 after:bg-red dark:after:bg-orange`
              }`}
              onClick={() => setActiveSubcategory(subcategory.categoryId)}
            >
              {subcategory.label}
            </button>
          ))}
        </div>
        {width && width >= 1024 && (
          <Ad id="1631036643870-0" slot="SG_lewa2" className="w-full mt-auto" />
        )}
      </div>

      <div className="w-screen h-5 col-span-12 mx-auto overflow-hidden max-w-screen-off-left lg:hidden">
        <ul className="box-content flex flex-row h-full pb-2 space-x-4 overflow-x-auto overflow-y-hidden max-w-screen-off-left scrollbar-hide">
          {SUBCATEGORIES.map((subcategory, index) => (
            <li
              key={`texts-mobile-subcategory-${index}`}
              className={`whitespace-nowrap font-display text-lg font-medium  leading-6 uppercase cursor-pointer ${
                subcategory.categoryId === activeSubcategory
                  ? "text-red dark:text-orange"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveSubcategory(subcategory.categoryId)}
            >
              {subcategory.label}
            </li>
          ))}
        </ul>
      </div>

      <div className="flex col-span-12 h-660px lg:col-span-6">
        {articlesQuery.data && articlesQuery.data.results.length > 0 && (
          <ArticleCard
            variant={ArticleCardVariant.PrimaryWithSummary}
            article={articlesQuery.data.results[0]}
          />
        )}
      </div>

      <div className="flex-col hidden col-span-3 space-y-5 lg:flex">
        {articlesQuery.data && articlesQuery.data.results.length > 1 && (
          <Fragment>
            <ArticleCard article={articlesQuery.data.results[1]} />

            <ArticleCard article={articlesQuery.data.results[2]} />
          </Fragment>
        )}
      </div>

      <div className="hidden col-span-9 space-x-5 h-80 lg:flex">
        {articlesQuery.data &&
          articlesQuery.data.results
            .slice(3, 6)
            .map((article, index) => (
              <ArticleCard key={`bottom-article-${index}`} article={article} />
            ))}
      </div>

      <div className="col-span-12 h-80 lg:hidden">
        <Carousel offset={0}>
          {articlesQuery.data &&
            articlesQuery.data.results
              .slice(1)
              .map((article, index) => (
                <ArticleCard
                  key={`carousel-article-${index}`}
                  article={article}
                />
              ))}
        </Carousel>
      </div>

      {width && width < 1024 && (
        <div className="col-span-12 mt-5">
          <Ad id="1631036643870-0" slot="SG_lewa2" className="mt-auto" />
        </div>
      )}
    </ContainerSection>
  );
}
