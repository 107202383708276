import ArticleCard, { ArticleCardVariant } from "components/ArticleCard";
import Button from "components/Button";
import Carousel from "components/Carousel";
import ContainerSection from "components/ContainerSection";
import Link from "next/link";
import { Fragment, ReactElement } from "react";
import { useArticlesQuery } from "utils/api/hooks";

const SUBCATEGORIES = [
  { label: "Wydarzenia", categoryId: 1 },
  { label: "Książki", categoryId: 1 },
  { label: "Seriale", categoryId: 1 },
  { label: "Filmy", categoryId: 1 },
  { label: "Muzyka", categoryId: 1 },
  { label: "Komiksy", categoryId: 1 },
  { label: "Planszówki", categoryId: 1 },
];

export default function Magazine(): ReactElement {
  // TODO: podział na subkategorie? na razie brak
  // const [activeSubcategory, setActiveSubcategory] = useState(0);

  const cultureQuery = useArticlesQuery({
    categoryId: 8,
    sort: "-isFeatured,-isPinned,-publishedAt",
    isRecommended: false,
  });

  return (
    <ContainerSection className="grid grid-cols-12 my-8 space-y-5 lg:space-x-5">
      <div className="flex justify-between col-span-12 lg:col-span-3 lg:flex-col lg:justify-start lg:items-start">
        <Link href={`/kultura`} passHref>
          <a className="text-2xl font-bold text-transparent uppercase lg:text-3xl bg-clip-text bg-gradient-to-br from-red to-red-200 dark:from-orange-600 dark:via-orange dark:to-orange-200 font-display">
            Kultura
          </a>
        </Link>

        {/* <div className="flex-col hidden w-3/4 mt-8 lg:flex">
          {[
            "Wydarzenia",
            "Książki",
            "Seriale",
            "Filmy",
            "Muzyka",
            "Komiksy",
            "Planszówki",
          ].map((subcategory, index) => (
            <button
              key={`magazine-subcategory-${index}`}
              className={`py-2 text-2xl font-medium relative text-left ${
                index === activeSubcategory
                  ? `text-red dark:text-orange`
                  : `text-gray-500`
              } uppercase border-b font-display border-gray-100 dark:border-black-100 ${
                index === activeSubcategory &&
                `after:w-14 after:h-1 after:rounded after:absolute after:right-0 after:bottom-0 after:bg-red dark:after:bg-orange`
              }`}
              onClick={() => setActiveSubcategory(index)}
            >
              {subcategory}
            </button>
          ))}
        </div> */}

        <div className="flex flex-row items-start justify-end space-x-2 lg:hidden">
          <Link href={`/kultura`} passHref>
            <Button $as="a">Wszystkie</Button>
          </Link>
        </div>
      </div>
      {/* 
      <div className="w-screen h-5 col-span-12 mx-auto overflow-hidden max-w-screen-off-left lg:hidden">
        <ul className="box-content flex flex-row h-full max-w-full pb-2 space-x-4 overflow-x-auto overflow-y-hidden">
          {[
            "Wydarzenia",
            "Książki",
            "Seriale",
            "Filmy",
            "Muzyka",
            "Komiksy",
            "Planszówki",
          ].map((subcategory, index) => (
            <li
              key={`magazine-mobile-subcategory-${index}`}
              className={`whitespace-nowrap font-display text-lg font-medium leading-6 uppercase cursor-pointer ${
                index === activeSubcategory
                  ? "text-red dark:text-orange"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveSubcategory(index)}
            >
              {subcategory}
            </li>
          ))}
        </ul>
      </div> */}

      <div className="col-span-12 h-80 lg:hidden">
        <Carousel offset={0}>
          {cultureQuery.data &&
            cultureQuery.data.results
              .slice(3)
              .map((article, index) => (
                <ArticleCard
                  key={`carousel-article-${index}`}
                  article={article}
                />
              ))}
        </Carousel>
      </div>

      <div className="flex-col hidden col-span-3 space-y-5 lg:flex">
        {cultureQuery.data && cultureQuery.data.results.length > 1 && (
          <Fragment>
            <ArticleCard article={cultureQuery.data.results[1]} />

            <ArticleCard article={cultureQuery.data.results[2]} />
          </Fragment>
        )}
      </div>
      <div className="flex col-span-12 h-660px lg:col-span-6">
        {cultureQuery.data && cultureQuery.data.results.length > 0 && (
          <ArticleCard
            variant={ArticleCardVariant.Primary}
            article={cultureQuery.data.results[0]}
          />
        )}
      </div>
    </ContainerSection>
  );
}
