import { Ad } from "components/Ads";
import ArticleCard, { ArticleCardVariant } from "components/ArticleCard";
import Button from "components/Button";
import Carousel from "components/Carousel";
import ContainerSection from "components/ContainerSection";
import { ChevronLeft, ChevronRight } from "components/Icons";
import Link from "next/link";
import { ReactElement, useState } from "react";
import { useArticlesQuery } from "utils/api/hooks";

export default function Featured(): ReactElement {
  const [carouselOffset, setCarouselOffset] = useState(0);

  const recommendedArticlesQuery = useArticlesQuery({
    isRecommended: true,
    sort: "position,-isFeatured,-isPinned,-publishedAt",
    limit: 5,
  });

  const newestArticlesQuery = useArticlesQuery({
    sort: "-publishedAt",
    limit: 15,
    isRecommended: false,
  });

  return (
    <ContainerSection>
      <div className="grid grid-cols-12 space-x-5">
        <div className="flex flex-col col-span-12 space-y-5 lg:h-full lg:col-span-6">
          <div className="flex-grow flex-shrink-0 h-96 lg:h-[500px]">
            <ArticleCard
              variant={ArticleCardVariant.Primary}
              article={recommendedArticlesQuery?.data?.results?.[0]}
            />
          </div>

          <div className="flex-shrink-0 lg:h-32">
            <Ad id="1631036272651-0" slot="SG_gora2" className="h-full" />
          </div>
        </div>

        <div className="flex-col hidden col-span-3 space-y-5 lg:flex lg:children:h-1/2">
          <ArticleCard article={recommendedArticlesQuery?.data?.results?.[1]} />

          <ArticleCard article={recommendedArticlesQuery?.data?.results?.[2]} />
        </div>

        <div className="flex-col hidden col-span-3 space-y-5 lg:flex lg:children:h-1/2">
          <ArticleCard article={recommendedArticlesQuery?.data?.results?.[3]} />

          <ArticleCard article={recommendedArticlesQuery?.data?.results?.[4]} />
        </div>
      </div>

      <div className="block w-full my-5 lg:hidden">
        <div className="h-80">
          <Carousel placeholder={ArticleCard}>
            {recommendedArticlesQuery.data &&
              recommendedArticlesQuery.data.results
                .slice(1)
                .map((article, index) => (
                  <ArticleCard
                    key={`carousel-article-${index}`}
                    article={article}
                  />
                ))}
          </Carousel>
        </div>
      </div>

      <div className="mt-5">
        <div className="flex justify-between">
          <Link href={`/najnowsze`} passHref>
            <a className="text-2xl font-bold text-gray-500 uppercase font-display hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-br hover:from-red hover:to-red-200 dark:hover:from-orange-600 dark:hover:via-orange dark:hover:to-orange-200">
              Najnowsze
            </a>
          </Link>

          <div className="flex items-start justify-end space-x-2">
            <Link href={`/najnowsze`} passHref>
              <Button $as="a">Wszystkie</Button>
            </Link>

            <Button
              disabled={carouselOffset <= 0}
              onClick={() =>
                setCarouselOffset(
                  (carouselOffset) =>
                    carouselOffset - (window?.innerWidth < 940 ? 1 : 4)
                )
              }
              icon={<ChevronLeft />}
              className="hidden lg:flex"
            />

            <Button
              disabled={
                process.browser && window.innerWidth < 940
                  ? carouselOffset > 5
                  : carouselOffset > 3
              }
              onClick={() =>
                setCarouselOffset(
                  (carouselOffset) =>
                    carouselOffset + (window?.innerWidth < 940 ? 1 : 4)
                )
              }
              icon={<ChevronRight />}
              className="hidden lg:flex"
            />
          </div>
        </div>

        <div className="w-full mt-5">
          <div className="h-80">
            <Carousel offset={carouselOffset} placeholder={ArticleCard}>
              {newestArticlesQuery.data &&
                newestArticlesQuery.data.results.map((article, index) => (
                  <ArticleCard
                    key={`carousel-article-${index}`}
                    article={article}
                  />
                ))}
            </Carousel>
          </div>
        </div>
      </div>
    </ContainerSection>
  );
}
