import { Ad } from "components/Ads";
import ArticleCard, { ArticleCardVariant } from "components/ArticleCard";
import Button from "components/Button";
import Carousel from "components/Carousel";
import ContainerSection from "components/ContainerSection";
import { ChevronLeft, ChevronRight } from "components/Icons";
import Link from "next/link";
import { Fragment, ReactElement, useState } from "react";
import { useArticlesQuery } from "utils/api/hooks";
import { useWindowSize } from "utils/hooks";

export default function News(): ReactElement {
  const [carouselOffset, setCarouselOffset] = useState(0);
  const { width } = useWindowSize();

  const newsQuery = useArticlesQuery({
    categoryId: 1,
    sort: "-isFeatured,-isPinned,-publishedAt",
    isRecommended: false,
  });

  return (
    <ContainerSection>
      <div className="grid grid-cols-12 space-y-5 lg:space-x-5">
        <div className="flex flex-col col-span-12 row-span-3 lg:col-span-3">
          <div className="flex justify-between">
            <Link href={`/newsy`} passHref>
              <a className="text-2xl font-bold text-transparent uppercase lg:text-3xl bg-clip-text bg-gradient-to-br from-red to-red-200 dark:from-orange-600 dark:via-orange dark:to-orange-200 font-display">
                Newsy
              </a>
            </Link>

            <div className="flex flex-row items-start justify-end space-x-2 lg:hidden">
              <Link href={`/newsy`} passHref>
                <Button $as="a">Wszystkie</Button>
              </Link>

              <Button
                disabled={carouselOffset <= 0}
                onClick={() =>
                  setCarouselOffset(
                    (carouselOffset) =>
                      carouselOffset - (window?.innerWidth < 940 ? 1 : 4)
                  )
                }
                icon={<ChevronLeft />}
                className="hidden lg:flex"
              />

              <Button
                disabled={
                  process.browser && window.innerWidth < 940
                    ? carouselOffset > 5
                    : carouselOffset > 3
                }
                onClick={() =>
                  setCarouselOffset(
                    (carouselOffset) =>
                      carouselOffset + (window?.innerWidth < 940 ? 1 : 4)
                  )
                }
                icon={<ChevronRight />}
                className="hidden lg:flex"
              />
            </div>
          </div>

          {width && width >= 1024 && (
            <Ad id="1631036584289-0" slot="SG_lewa1" className="mt-auto" />
          )}
        </div>

        <div className="hidden lg:flex col-span-9 !-mt-1   justify-end">
          <Link href={`/newsy`} passHref>
            <Button $as="a">Wszystkie</Button>
          </Link>
        </div>

        <div className="flex col-span-12 h-660px lg:col-span-6">
          {newsQuery.data && newsQuery.data.results.length > 0 && (
            <ArticleCard
              variant={ArticleCardVariant.Primary}
              article={newsQuery.data.results[0]}
            />
          )}
        </div>

        <div className="flex-col hidden col-span-3 space-y-5 lg:flex">
          {newsQuery.data && newsQuery.data.results.length > 1 && (
            <Fragment>
              <ArticleCard article={newsQuery.data.results[1]} />

              <ArticleCard article={newsQuery.data.results[2]} />
            </Fragment>
          )}
        </div>

        <div className="hidden col-span-9 mt-5 space-x-5 h-80 lg:flex">
          {newsQuery.data &&
            newsQuery.data.results
              .slice(3, 6)
              .map((article, index) => (
                <ArticleCard
                  key={`bottom-article-${index}`}
                  article={article}
                />
              ))}
        </div>
        <div className="col-span-12 mt-5 h-80 lg:hidden">
          <Carousel offset={carouselOffset}>
            {newsQuery.data &&
              newsQuery.data.results
                .slice(1)
                .map((article, index) => (
                  <ArticleCard
                    key={`carousel-article-${index}`}
                    article={article}
                  />
                ))}
          </Carousel>
        </div>

        {width && width < 1024 && (
          <div className="col-span-12 mt-5">
            <Ad id="1631036584289-0" slot="SG_lewa1" className="mt-auto" />
          </div>
        )}
      </div>
    </ContainerSection>
  );
}
