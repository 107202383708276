import ArticleCard, { ArticleCardVariant } from "components/ArticleCard";
import Button, { ButtonSize } from "components/Button";
import Carousel from "components/Carousel";
import Image from "next/image";
import Link from "next/link";
import { useTheme } from "next-themes";
import { Fragment, ReactElement, useEffect, useRef, useState } from "react";
import { Section } from "utils/api/models/Section";
import { composeClassName } from "utils/misc";

type CustomProps = {
  section: Section;
};

export default function Custom({ section }: CustomProps): ReactElement {
  const [carouselOffset, setCarouselOffset] = useState(0);
  const [isScreening, setIsScreening] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState<string>();

  const { theme } = useTheme();

  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const observer = new MutationObserver(function () {
        setIsScreening(!!window.document.body.style.backgroundImage);
      });

      observer.observe(window.document.body, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }
  }, []);

  useEffect(() => {
    setBackgroundColor(
      theme === "light"
        ? section.lightBackgroundColor
        : section.darkBackgroundColor
    );
  }, [theme]);

  return (
    <section ref={sectionRef} className={composeClassName("my-8 relative")}>
      {!isScreening && (
        <div
          className="absolute left-0 right-0 z-10 -top-8 -bottom-8"
          style={{ backgroundColor }}
        />
      )}

      <div
        className={composeClassName(
          "px-3 mx-auto max-w-screen-2xl 2xl:px-0 relative children:z-10"
        )}
      >
        {isScreening && (
          <div
            className="absolute left-0 right-0 -top-8 2xl:-left-8 2xl:-right-8 -bottom-8"
            style={{ backgroundColor }}
          />
        )}

        <div className="relative grid grid-cols-12 space-y-5 lg:space-x-5">
          <div className="flex flex-col col-span-12 row-span-2 lg:col-span-3">
            <div className="flex flex-col h-full">
              <h3 className="text-2xl font-bold text-transparent uppercase lg:text-3xl bg-clip-text bg-gradient-to-br from-red to-red-200 dark:from-orange-600 dark:via-orange dark:to-orange-200 font-display">
                {section.name}
              </h3>

              {section.subtitle && (
                <h4 className="mt-3 font-mono text-xs font-bold text-gray-500 uppercase">
                  {section.subtitle}
                </h4>
              )}

              {section.bannerUrl && (
                <img
                  src={section.bannerUrl}
                  className="hidden mt-auto lg:block lg:w-2/3"
                />
              )}

              {section.hasWidget && (
                <div
                  className={composeClassName(
                    "hidden lg:flex w-full px-4 py-5 rounded-lg mt-auto",
                    "flex-col lg:w-2/3",
                    !section.widgetBackgroundColor && "bg-gray-500"
                  )}
                  style={{
                    backgroundColor: section.widgetBackgroundColor,
                  }}
                >
                  <div className="text-lg font-medium text-black uppercase font-display">
                    {section.widgetTitle}
                  </div>

                  {section.widgetButtonTargetUrl && (
                    <Link href={section.widgetButtonTargetUrl} passHref>
                      <Button
                        className="flex items-center justify-between py-3 pl-3 pr-5 mt-4 text-black uppercase border-black border-1"
                        size={ButtonSize.XLarge}
                        $as="a"
                        target="_blank"
                        rel="norefferer noopener"
                      >
                        {section.widgetButtonIcon && (
                          <img
                            src={section.widgetButtonIcon}
                            className="w-4 h-4"
                          />
                        )}

                        <span>{section.widgetButtonLabel}</span>
                      </Button>
                    </Link>
                  )}

                  {section.widgetImageUrl && (
                    <div
                      className={composeClassName(
                        "relative",
                        "w-full mt-5 h-64"
                      )}
                    >
                      <Image
                        src={`${section.widgetImageUrl}?preset=medium`}
                        blurDataURL={`${section.widgetImageUrl}?preset=thumbnail`}
                        placeholder="blur"
                        layout="fill"
                        objectFit="cover"
                        className="rounded-md"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="flex col-span-12 h-660px lg:col-span-6 lg:!mt-0">
            {section.articles.length > 0 && (
              <ArticleCard
                variant={ArticleCardVariant.Primary}
                article={section.articles[0]}
              />
            )}
          </div>

          <div className="flex-col hidden col-span-3 space-y-5 lg:flex lg:!mt-0">
            {section.articles.length > 1 && (
              <Fragment>
                <ArticleCard article={section.articles[1]} />

                <ArticleCard article={section.articles[2]} />
              </Fragment>
            )}
          </div>

          <div className="hidden col-span-9 mt-5 space-x-5 h-80 lg:flex">
            {section.articles &&
              section.articles
                .slice(3, 6)
                .map((article, index) => (
                  <ArticleCard
                    key={`bottom-article-${index}`}
                    article={article}
                  />
                ))}
          </div>
          <div className="col-span-12 mt-5 h-80 lg:hidden">
            <Carousel offset={carouselOffset}>
              {section.articles &&
                section.articles
                  .slice(1)
                  .map((article, index) => (
                    <ArticleCard
                      key={`carousel-article-${index}`}
                      article={article}
                    />
                  ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}
