import { Comment, Play } from "components/Icons";
import Image from "components/Image";
import Tag from "components/Tag";
import { format, formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import Link from "next/link";
import { Fragment, ReactElement, useState } from "react";
import { Video, VideoType } from "utils/api/models";
import { composeClassName, escapeHtml, secondsToHHMMSS } from "utils/misc";

export enum VideoCardVariant {
  // Primary
  // * uppercase & large title
  Primary = "primary",

  // Primary without data
  // * only video preview
  PrimaryWithoutData = "primary-without-data",

  // Default
  Default = "default",

  // Horizontal
  // * no elevation
  Horizontal = "horizontal",
}

type VideoCardProps = {
  variant?: VideoCardVariant;
  video?: Video;
  url?: string;
  hideCategory?: boolean;
};

export default function VideoCard({
  variant = VideoCardVariant.Default,
  video,
  url,
}: VideoCardProps): ReactElement {
  if (!video) {
    return (
      <div className="w-full h-full bg-gray-200 rounded-xl animate-pulse dark:bg-black-300" />
    );
  }

  const [hasImageLoaded, setHasImageLoaded] = useState(false);

  const youtubeId =
    video.source && video?.type === VideoType.YouTube
      ? RegExp(/\?v=(.{11})/gm).exec(video.source)
      : [];

  const twitchId =
    video.source && video?.type === VideoType.Twitch
      ? RegExp(
          /^(?:https?:\/\/)?(?:www\.|go\.)?twitch\.tv\/([a-z0-9_]+)($|\?)/gm
        ).exec(video.source)
      : [];

  function getTextStyles() {
    switch (variant) {
      case VideoCardVariant.Primary:
        return "text-3xl lg:text-5xl font-medium text-black dark:text-white uppercase font-display line-clamp-5";

      case VideoCardVariant.Horizontal:
        return "text-lg lg:text-3xl font-medium text-black dark:text-white lg:uppercase font-display line-clamp-5 group-hover:text-orange";

      case VideoCardVariant.Default:
      default:
        return "text-2xl font-medium font-display text-black dark:text-white uppercase line-clamp-3 mt-2";
    }
  }

  return (
    <Link href={url || `/wideo/${video?.slug}`} passHref>
      <a
        className={composeClassName(
          `relative flex group flex-grow w-full h-full cursor-pointer transition-fix `,
          variant === VideoCardVariant.Horizontal
            ? "h-44 pr-4 content-start"
            : "flex-col flex-wrap"
        )}
      >
        {variant !== VideoCardVariant.Primary &&
          variant !== VideoCardVariant.PrimaryWithoutData && (
            <div
              className={composeClassName(
                `absolute inset-0 z-0 rounded-xl overflow-hidden`,
                variant === VideoCardVariant.Horizontal && "w-36 lg:w-80",
                variant === VideoCardVariant.Default && "h-52"
              )}
              aria-hidden
            >
              <Play className="absolute z-10 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" />

              {video?.thumbnailUrl && video?.thumbnailUrl.includes("http") ? (
                <div
                  className={composeClassName(
                    "absolute inset-0 transition-transform",
                    variant !== VideoCardVariant.Horizontal &&
                      "group-hover:scale-110"
                  )}
                >
                  <Image
                    src={`${video?.thumbnailUrl}?preset=medium`}
                    blurDataURL={`${video?.thumbnailUrl}?preset=thumbnail`}
                    layout="fill"
                    placeholder="blur"
                    objectFit="cover"
                    onLoadingComplete={() => setHasImageLoaded(true)}
                  />
                </div>
              ) : video?.type === VideoType.YouTube ? (
                <div
                  className={composeClassName(
                    "absolute inset-0 transition-transform",
                    variant !== VideoCardVariant.Horizontal &&
                      "group-hover:scale-110"
                  )}
                >
                  <Image
                    src={`https://img.youtube.com/vi/${
                      youtubeId?.[1] || ""
                    }/maxresdefault.jpg`}
                    blurDataURL={`https://img.youtube.com/vi/${
                      youtubeId?.[1] || ""
                    }/default.jpg`}
                    layout="fill"
                    placeholder="blur"
                    objectFit="cover"
                    onLoadingComplete={() => setHasImageLoaded(true)}
                  />
                </div>
              ) : null}
            </div>
          )}

        <div
          className={composeClassName(
            "z-10 flex items-start space-x-3 m-4 h-6",
            variant === VideoCardVariant.Horizontal &&
              "w-36 lg:w-80 flex-shrink-0"
          )}
        >
          {video?.labels &&
            video?.labels.map((label, index) => (
              <Tag
                key={`video-label-${index}`}
                color="text-white"
                style={{ backgroundColor: label.color }}
              >
                {label.name}
              </Tag>
            ))}

          {video?.duration && video?.type !== VideoType.Twitch && (
            <Tag color="text-white" background="bg-black bg-opacity-60">
              {secondsToHHMMSS(Number(video?.duration))}
            </Tag>
          )}
        </div>

        {(variant === VideoCardVariant.Primary ||
          variant === VideoCardVariant.PrimaryWithoutData) && (
          <div className="overflow-hidden bg-black h-52 -mt-14 rounded-xl sm:h-82 md:h-120 lg:h-135 xl:h-150">
            {video?.type === VideoType.YouTube ? (
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${
                  youtubeId?.[1] || ""
                }?showinfo=0`}
                frameBorder="0"
                allowFullScreen
              />
            ) : video?.type === VideoType.Facebook ? (
              <iframe
                src={`https://www.facebook.com/plugins/video.php?href=${escapeHtml(
                  video?.source
                )}&show_text=false&width=1080`}
                width="100%"
                height="100%"
                scrolling="no"
                frameBorder="0"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                allowFullScreen={true}
              />
            ) : video?.type === VideoType.Twitch ? (
              <iframe
                src={`https://player.twitch.tv/?channel=${
                  twitchId?.[1] || ""
                }&parent=${
                  typeof window !== "undefined"
                    ? window?.location?.hostname
                    : "cdaction.pl"
                }`}
                frameBorder="0"
                allowFullScreen={true}
                scrolling="no"
                width="100%"
                height="100%"
              />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: video?.source }} />
            )}
          </div>
        )}

        {variant !== VideoCardVariant.PrimaryWithoutData && (
          <div
            className={composeClassName(
              "z-10",
              variant !== VideoCardVariant.Primary ? "mt-auto" : "mt-5",
              variant === VideoCardVariant.Default && "mt-40",
              variant === VideoCardVariant.Horizontal && "h-full flex flex-col"
            )}
          >
            <h2 className={`mb-4 ${getTextStyles()}`}>{video?.title}</h2>

            <div
              className={composeClassName(
                "flex space-x-7",
                variant === VideoCardVariant.Horizontal && "mt-auto"
              )}
            >
              <Tag
                color={"text-black dark:text-white"}
                border={"border-black dark:border-white"}
                outlined
              >
                Wideo
              </Tag>

              <Tag
                color={"text-black dark:text-white"}
                border="border-transparent"
                outlined
              >
                <Fragment>
                  {+new Date(video?.publishedAt) >= +new Date() - 86400000 &&
                    formatDistanceToNow(new Date(video?.publishedAt), {
                      locale: pl,
                      addSuffix: true,
                    })}
                  {+new Date(video?.publishedAt) < +new Date() - 86400000 &&
                    format(new Date(video?.publishedAt), "d.MM.yyyy", {
                      locale: pl,
                    })}
                </Fragment>
              </Tag>

              {!!video?.commentCount && (
                <div
                  className={composeClassName(
                    "flex items-center text-black dark:text-white"
                  )}
                >
                  <Comment />

                  <span
                    className={`ml-3 font-bold font-mono text-xs uppercase`}
                  >
                    {video?.commentCount}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </a>
    </Link>
  );
}
