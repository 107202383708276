import ArticleCard, { ArticleCardVariant } from "components/ArticleCard";
import Button from "components/Button";
import Carousel from "components/Carousel";
import ContainerSection from "components/ContainerSection";
import { ChevronLeft, ChevronRight } from "components/Icons";
import Link from "next/link";
import { ReactElement, useState } from "react";
import { useArticlesQuery } from "utils/api/hooks";

export default function Tech(): ReactElement {
  const [carouselOffset, setCarouselOffset] = useState(0);

  const techQuery = useArticlesQuery({
    categoryId: "in:9,10,11",
    sort: "-isFeatured,-isPinned,-publishedAt",
    isRecommended: false,
  });

  return (
    <ContainerSection className="grid grid-cols-12 my-8 space-y-5">
      <div className="flex justify-between col-span-12">
        <Link href={`/technologie`} passHref>
          <a className="text-2xl font-bold text-transparent uppercase lg:text-3xl bg-clip-text bg-gradient-to-br from-red to-red-200 dark:from-orange-600 dark:via-orange dark:to-orange-200 font-display">
            Technologie
          </a>
        </Link>

        <div className="flex flex-row items-start justify-end space-x-2">
          <Button
            disabled={carouselOffset <= 0}
            onClick={() =>
              setCarouselOffset(
                (carouselOffset) =>
                  carouselOffset - (window?.innerWidth < 940 ? 1 : 4)
              )
            }
            className="hidden lg:flex"
          >
            <ChevronLeft />
          </Button>

          <Button
            onClick={() =>
              setCarouselOffset(
                (carouselOffset) =>
                  carouselOffset + (window?.innerWidth < 940 ? 1 : 4)
              )
            }
            className="hidden lg:flex"
          >
            <ChevronRight />
          </Button>
        </div>
      </div>

      <div className="col-span-12 h-120">
        <Carousel offset={carouselOffset}>
          {techQuery.data &&
            techQuery.data.results.map((article, index) => (
              <ArticleCard
                variant={ArticleCardVariant.SecondaryWithTags}
                key={`carousel-article-${index}`}
                article={article}
              />
            ))}
        </Carousel>
      </div>
    </ContainerSection>
  );
}
